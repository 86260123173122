import React, { useEffect, useState } from 'react'
import './Home.scss'


export default function Home() {
    const [timerDays,setTimerDays]=useState();
    const [timerHours,setTimerHours]=useState();
    const [timerMinutes,setTimerMinutes]=useState();
    const [timerSeconds,setTimerSeconds]=useState();

    let interval;
    const startTimer = () => {
        const countDownDate = new Date("Jan 1,2023").getTime();
        interval = setInterval(()=>{
            const now = new Date().getTime();
            const distance = countDownDate - now;
            const days = Math.floor(distance/(24*60*60*1000));
            const hours = Math.floor((distance % (24*60*60*1000)/(1000*60*60)));
            const minutes = Math.floor((distance % (60*60*1000)/(1000*60)));
            const seconds = Math.floor((distance % (60*1000)/(1000)));

            if(distance<0){
                //  Stop Timer
                clearInterval(interval.current)
            }else{
                // Update Timer
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        })
    }

    useEffect(()=>{
        startTimer()
    })

    return (
    <section className='Home'>
        <div className='coming-soon'>
            <div className='d-flex justify-content-center align-items-center'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <span className='coming-soon-txt'>Coming soon</span>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='d-flex justify-content-start align-items-center flex-column'>
                            <span className='timer-num'>{timerDays}</span>
                            <span className='timer-txt'>Days</span>
                        </div>
                        <div>&nbsp;:&nbsp;</div>
                        <div className='d-flex justify-content-start align-items-center flex-column'>
                            <span className='timer-num'>{timerHours}</span>
                            <span className='timer-txt'>Hours</span>
                        </div>
                        <div>&nbsp;:&nbsp;</div>
                        <div className='d-flex justify-content-start align-items-center flex-column'>
                            <span className='timer-num'>{timerMinutes}</span>
                            <span className='timer-txt'>Minutes</span>
                        </div>
                        <div>&nbsp;:&nbsp;</div>
                        <div className='d-flex justify-content-start align-items-center flex-column'>
                            <span className='timer-num'>{timerSeconds}</span>
                            <span className='timer-txt'>Seconds</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}


import React from 'react'
import './NotFound.scss'

export default function NotFound() {
    return (
        <section className='NotFound'>
            <div className='not-found'>
                <div className='d-flex justify-content-start'>
                    <div className='d-flex flex-column justify-content-start align-items-center'>
                        <span className='not-found-txt'>404 error</span>
                        <span className='not-found-txt'>page not found</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

import './App.scss';
import Home from './pages/home/Home';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from './pages/not-found/NotFound';


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/'} exact component={Home} />
        <Route path={'*'} exact component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
